import {
  CancelOrderReq,
  CancelOrderReqRaw,
  emptyCancelOrderReq,
  emptyOrder,
  emptyOrderWithProofs,
  FactoryState,
  HEX_0,
  MakeOrderReqRaw,
  Order,
  OrderBookState,
  OrderWithProofs,
  RawOrder,
  RouterTakeReq,
  TakeReqRaw,
} from './types';
import { BaseContract, BigNumber, BytesLike, ContractTransaction, ethers, utils } from 'ethers';
import { MerkleOrderbook, MerkleOrderbookFactory, PolicyToken, SMT } from '~common/generated/contract-types';
import { getProofOfLeafIndex } from './MerkleUtils';
import { IEthersContext } from 'eth-hooks2/models';
import { JsonRpcProvider, JsonRpcSigner, Log } from '@ethersproject/providers';
import { LogDescription } from '@ethersproject/abi';
import { asEthersAdaptor } from 'eth-hooks2/functions';

import { recursiveGetLogs } from './CommonUtils';
import NonDeployedContracts from '~common/generated/injectable-abis/hardhat_non_deployed_contracts.json';
const MerkleOrderBookABI = NonDeployedContracts.MerkleOrderBook;
const PolicyTokenABI = NonDeployedContracts.PolicyToken;
export async function takeManyRouter(
  factoryState: FactoryState,
  allStates: OrderBookState[],
  rawReqs: TakeReqRaw[],
  signer: JsonRpcSigner
): Promise<[OrderBookState[], ContractTransaction]> {
  const reqs: RouterTakeReq[] = [];
  let totalCash = BigNumber.from(0);
  const policyTokens = new Map<number, BigNumber>();
  for (const rawReq of rawReqs) {
    const obi = rawReq.orderBookIndex.toNumber();
    let state = allStates[obi];
    if (state === undefined) {
      throw new Error('incorrect allStates');
      // state = await getOrderBookState(ethersAppContext,factoryState, obi);
    }
    if (rawReq.isSell) {
      // wants to take a sellOrder, pays cash
      let takeReq: RouterTakeReq;
      [state, takeReq] = await modifyStateForTakeSellHead(state, rawReq.amount);
      state.cancelledOrders = [emptyCancelOrderReq];
      const value = takeReq.amount.mul(takeReq.headOrder.order.orderPrice);
      totalCash = totalCash.add(value);
      reqs.push(takeReq);
    } else {
      let takeReq: RouterTakeReq;
      [state, takeReq] = await modifyStateForTakeBuyHead(state, rawReq.amount);
      state.cancelledOrders = [emptyCancelOrderReq];
      const policyAmt = policyTokens.get(obi);
      if (policyAmt === undefined) {
        policyTokens.set(obi, rawReq.amount);
      } else {
        policyTokens.set(obi, rawReq.amount.add(policyAmt));
      }
      reqs.push(takeReq);
    }

    allStates[obi] = state;
  }
  if (await (await factoryState.cashToken.balanceOf(signer._address)).lt(totalCash)) {
    throw new Error('you dont have enough cashtoken');
  }

  if (totalCash.gt(0)) {
    const tx = await factoryState.cashToken.connect(signer).approve(factoryState.orderBookRouter.address, totalCash);
    await tx.wait();
  }

  for (const [key, value] of policyTokens) {
    const state = allStates[key];
    if (state === undefined) {
      throw new Error('ts reqs this');
    }

    await state.policyToken.connect(signer).approve(factoryState.orderBookRouter.address, value);
  }
  return [allStates, await factoryState.orderBookRouter.connect(signer).takeMany(reqs)];
}

export const getOrderBookState = async (
  provider: JsonRpcProvider,
  factoryState: FactoryState,
  orderBookIndex: number
): Promise<OrderBookState> => {
  if (factoryState.orderbooks[orderBookIndex] === undefined) {
    throw new Error('invalid orderBookIndex');
  }
  const orderBook = getOrderBookObj(provider, factoryState.orderbooks[orderBookIndex][0]);
  let state: OrderBookState = {
    orderBook,
    policyToken: getPolicyTokenObj(provider, await orderBook.policyToken()),
    cashToken: factoryState.cashToken,
    orderBookIndex,
    address: factoryState.orderbooks[orderBookIndex][0],
    OrdersByIndex: new Map<string, Order>(),
    OrdersByDigest: new Map<BytesLike, Order>(),
    sellHead: HEX_0,
    buyHead: HEX_0,
    Fills: new Map<string, BigNumber>(),
    sellNext: new Map<string, BigNumber>(),
    buyNext: new Map<string, BigNumber>(),
    cancelledOrders: [],
  };

  const logs = await getLogForOrderBookAddress(provider, state.address, factoryState.orderbooks[orderBookIndex][1]);
  for (const event of logs) {
    if (event.name === 'NewSellOrder') {
      const rawOrder = {
        maker: event.args.maker,
        orderAmount: event.args.orderAmount,
        orderPrice: event.args.orderPrice,
        isSell: true,
        isCollateralOrder: event.args.isCollateralOrder,
      };
      let makeOrderReqRaw;
      [state, makeOrderReqRaw] = await modifyStateForMakeOrder(state, rawOrder, event.args.index);
      if (!makeOrderReqRaw.newOrder.order.index.eq(event.args.index)) {
        throw new Error('getOrderBookState sell used wrong index');
      }
    } else if (event.name === 'NewBuyOrder') {
      const rawOrder = {
        maker: event.args.maker,
        orderAmount: event.args.orderAmount,
        orderPrice: event.args.orderPrice,
        isSell: false,
        isCollateralOrder: event.args.isCollateralOrder,
      };
      let makeOrderReqRaw;
      [state, makeOrderReqRaw] = await modifyStateForMakeOrder(state, rawOrder, event.args.index);
      if (!makeOrderReqRaw.newOrder.order.index.eq(event.args.index)) {
        throw new Error('localstate sell used wrong index');
      }
    } else if (event.name === 'TakeSellOrder') {
      // passing fake signer in, he doesnt sign anyway
      [state] = await modifyStateForTakeSellHead(state, event.args.takeAmount);
    } else if (event.name === 'TakeBuyOrder') {
      // passing fake signer in, he doesnt sign anyway
      [state] = await modifyStateForTakeBuyHead(state, event.args.takeAmount);
    } else if (event.name === 'CancelSellOrder') {
      // passing fake signer in, he doesnt sign anyway
      [state] = await modifyStateForCancelOrder(state, event.args.index);
    } else if (event.name === 'CancelBuyOrder') {
      // passing fake signer in, he doesnt sign anyway
      [state] = await modifyStateForCancelOrder(state, event.args.index);
    }
  }
  return await updateCancelledOrders(factoryState, state);
};

function getLeavesFromOrders(OrdersByDigest: Map<BytesLike, Order>): BytesLike[] {
  const returnArray: BytesLike[] = [];
  for (const [digest, order] of OrdersByDigest) {
    returnArray[order.index.toNumber()] = digest;
  }
  for (let i = 0; i < returnArray.length; ++i) {
    if (!returnArray[i]) {
      returnArray[i] = HEX_0;
    }
  }
  return returnArray;
}
function getAvailIndex(OrdersByIndex: Map<string, Order>): BigNumber {
  let returnIndex;
  let i = getRandomIndex();
  while (returnIndex === undefined) {
    if (OrdersByIndex.get(i.toString()) === undefined) {
      returnIndex = BigNumber.from(i);
    }
    i = getRandomIndex();
  }
  if (returnIndex === undefined) {
    throw new Error('out of orders, max of 256');
  }
  return returnIndex;
}
function getRandomIndex() {
  return Math.floor(Math.random() * 7) + 1; // 256 total, 0 always inc
}
const modifyStateForMakeOrder = (
  state: OrderBookState,
  rawOrder: RawOrder,
  index: BigNumber | undefined
): [OrderBookState, MakeOrderReqRaw, BytesLike] => {
  if (index === undefined) {
    index = getAvailIndex(state.OrdersByIndex);
  }
  const order: Order = {
    maker: rawOrder.maker,
    orderAmount: rawOrder.orderAmount,
    orderPrice: rawOrder.orderPrice,
    isSell: rawOrder.isSell,
    isCollateralOrder: rawOrder.isCollateralOrder,
    index,
  };
  const digest = digestOrder(state.address, order);

  // need to return newOrder, signature, headOrder, prevOrder and injectOrder
  // we need to get proofs we need before we insert into order
  let headOrder = emptyOrder;
  let prevOrder = emptyOrderWithProofs;
  let injectOrder = emptyOrderWithProofs;
  if (order.isSell) {
    if (state.sellHead === HEX_0) {
      // sellHead is empty,
      state.sellHead = digest;
      // do nothing, makeOrder expects empty orders
    } else {
      const sellHeadOrder = state.OrdersByDigest.get(state.sellHead);
      if (sellHeadOrder === undefined) {
        throw new Error('cant get sellhead order, not supposed to happen');
      }
      if (sellHeadOrder.orderPrice.gt(order.orderPrice)) {
        // order is going to replace sellhead)
        state.sellNext.set(order.index.toString(), sellHeadOrder.index);
        state.sellHead = digest;
        headOrder = sellHeadOrder;
        // we are replacing head, only need to supply headorder
      } else {
        // order is not cheaper then sellhead, gona check nextindex
        const sellNextIndex = state.sellNext.get(sellHeadOrder.index.toString());
        if (sellNextIndex === undefined) {
          // sellHead has no next,inject AFTER sellhead
          state.sellNext.set(sellHeadOrder.index.toString(), order.index);
          headOrder = sellHeadOrder;
          // we are injecting AFTER head, dont need more proofs
        } else {
          let prevIndex = sellHeadOrder.index;
          let nextIndex = sellNextIndex;
          while (headOrder === emptyOrder) {
            const nextOrder = state.OrdersByIndex.get(nextIndex.toString());
            if (nextOrder === undefined) {
              throw new Error('cant get nextOrder, not supposed to happen');
            }

            if (nextOrder.orderPrice.gt(order.orderPrice)) {
              // we wanna inject BEFORE nextOrder
              prevOrder = getOrderWithProofs(state, prevIndex);
              injectOrder = getOrderWithProofs(state, nextOrder.index);
              state.sellNext.set(prevIndex.toString(), order.index);
              state.sellNext.set(order.index.toString(), nextOrder.index);
              headOrder = sellHeadOrder;
            } else {
              const tmpNextIndex = state.sellNext.get(nextOrder.index.toString());
              if (tmpNextIndex === undefined) {
                // nextOrder doesnt have a next,inject AFTER next order
                state.sellNext.set(nextOrder.index.toString(), order.index);
                headOrder = sellHeadOrder;
                injectOrder = getOrderWithProofs(state, nextOrder.index);
              } else {
                prevIndex = nextIndex;
                nextIndex = tmpNextIndex;
              }
            }
          }
        }
      }
    }
  } else {
    // buy order
    if (state.buyHead === HEX_0) {
      // buyHead is empty,
      state.buyHead = digest;
      // do nothing, makeOrder expects empty orders
    } else {
      const buyHeadOrder = state.OrdersByDigest.get(state.buyHead);
      if (buyHeadOrder === undefined) {
        throw new Error('cant get buyHead order, not supposed to happen');
      }
      if (buyHeadOrder.orderPrice.lt(order.orderPrice)) {
        // order is going to replace buyHead)
        state.buyNext.set(order.index.toString(), buyHeadOrder.index);
        state.buyHead = digest;
        headOrder = buyHeadOrder;
        // we are replacing head, only need to supply headorder
      } else {
        // order order is lesser then buyhead
        const buyNextIndex = state.buyNext.get(buyHeadOrder.index.toString());
        if (buyNextIndex === undefined) {
          // sellHead has no next, inject AFTER sellhead
          state.buyNext.set(buyHeadOrder.index.toString(), order.index);
          headOrder = buyHeadOrder;
          // we are injecting AFTER head, dont need more proofs
        } else {
          let prevIndex = buyHeadOrder.index;
          let nextIndex = buyNextIndex;
          while (headOrder === emptyOrder) {
            const nextOrder = state.OrdersByIndex.get(nextIndex.toString());
            if (nextOrder === undefined) {
              throw new Error('cant get nextOrder, not supposed to happen');
            }

            if (nextOrder.orderPrice.lt(order.orderPrice)) {
              // we wanna inject BEFORE nextOrder
              prevOrder = getOrderWithProofs(state, prevIndex);
              injectOrder = getOrderWithProofs(state, nextOrder.index);
              // console.log("injectOrder",injectOrder)
              state.buyNext.set(prevIndex.toString(), order.index);
              state.buyNext.set(order.index.toString(), nextOrder.index);
              headOrder = buyHeadOrder;
            } else {
              const tmpNextIndex = state.buyNext.get(nextOrder.index.toString());
              if (tmpNextIndex === undefined) {
                // nextOrder doesnt have a next,inject AFTER next order
                state.buyNext.set(nextOrder.index.toString(), order.index);
                headOrder = buyHeadOrder;
                injectOrder = getOrderWithProofs(state, nextOrder.index);
              } else {
                prevIndex = nextIndex;
                nextIndex = tmpNextIndex;
              }
            }
          }
        }
      }
    }
  }
  state.OrdersByDigest.set(digest, order);
  state.OrdersByIndex.set(order.index.toString(), order);
  const newOrderWithProofs = getOrderWithProofs(state, order.index);
  const makeOrderReq: MakeOrderReqRaw = {
    newOrder: newOrderWithProofs,
    headOrder,
    prevOrder,
    injectOrder,
  };
  return [state, makeOrderReq, digest];
};
function modifyStateForTakeSellHead(state: OrderBookState, amount: BigNumber): [OrderBookState, RouterTakeReq] {
  if (state.sellHead === HEX_0) {
    throw new Error('no sellHead');
  }
  const headOrder = state.OrdersByDigest.get(state.sellHead);
  if (headOrder === undefined) {
    throw new Error('cant get sellHead Order');
  }
  const headOrderWithProofs = getOrderWithProofs(state, headOrder.index);
  let headOrderFills = state.Fills.get(headOrder.index.toString());
  if (headOrderFills === undefined) {
    headOrderFills = BigNumber.from(0);
  }
  const headAmountLeft = headOrder.orderAmount.sub(headOrderFills);
  let nextOrder = emptyOrderWithProofs;
  if (amount.gt(headAmountLeft)) {
    throw new Error('amount too large');
  }
  const nextOrderIndex = state.sellNext.get(headOrder.index.toString());
  if (amount.eq(headAmountLeft)) {
    const tmpSellHead = state.sellHead;
    if (nextOrderIndex) {
      // supply nextOrder if amount fills sellhead, AND there is a next order

      nextOrder = getOrderWithProofs(state, nextOrderIndex);
      state.sellHead = digestOrder(state.address, nextOrder.order);
    } else {
      state.sellHead = HEX_0;
    }

    state.Fills.delete(headOrder.index.toString());
    state.sellNext.delete(headOrder.index.toString());
    state.OrdersByDigest.delete(tmpSellHead);
    state.OrdersByIndex.delete(headOrder.index.toString());
  } else {
    state.Fills.set(headOrder.index.toString(), headOrderFills.add(amount));
  }
  const req: RouterTakeReq = {
    orderBookIndex: BigNumber.from(state.orderBookIndex),
    amount,
    headOrder: headOrderWithProofs,
    headsNextOrder: nextOrder,
    cancelledOrders: state.cancelledOrders,
  };
  return [state, req];
}
function modifyStateForTakeBuyHead(state: OrderBookState, amount: BigNumber): [OrderBookState, RouterTakeReq] {
  if (state.buyHead === HEX_0) {
    throw new Error('there is no buy head to take!');
  }

  const headOrder = state.OrdersByDigest.get(state.buyHead);
  if (headOrder === undefined) {
    throw new Error('buy headOrder cant be found not supposed to happen');
  }
  const headOrderWithProofs = getOrderWithProofs(state, headOrder.index);
  let headOrderFills = state.Fills.get(headOrder.index.toString());
  if (headOrderFills === undefined) {
    headOrderFills = BigNumber.from(0);
  }
  const headAmountLeft = headOrder.orderAmount.sub(headOrderFills);
  let nextOrder = emptyOrderWithProofs;
  if (amount.gt(headAmountLeft)) {
    throw new Error('amount too large');
  }
  const nextOrderIndex = state.buyNext.get(headOrder.index.toString());
  if (amount.eq(headAmountLeft)) {
    const tmpBuyHead = state.buyHead;
    if (nextOrderIndex !== undefined) {
      // supply nextOrder if amount fills sellhead, AND there is a next order

      nextOrder = getOrderWithProofs(state, nextOrderIndex);
      state.buyHead = digestOrder(state.address, nextOrder.order);
    } else {
      state.buyHead = HEX_0;
    }
    state.Fills.delete(headOrder.index.toString());
    state.buyNext.delete(headOrder.index.toString());
    state.OrdersByDigest.delete(tmpBuyHead);
    state.OrdersByIndex.delete(headOrder.index.toString());
  } else {
    state.Fills.set(headOrder.index.toString(), headOrderFills.add(amount));
  }
  const req: RouterTakeReq = {
    orderBookIndex: BigNumber.from(state.orderBookIndex),
    amount,
    headOrder: headOrderWithProofs,
    headsNextOrder: nextOrder,
    cancelledOrders: state.cancelledOrders,
  };
  return [state, req];
}
function modifyStateForCancelOrder(state: OrderBookState, orderIndex: BigNumber): [OrderBookState, CancelOrderReqRaw] {
  const order = state.OrdersByIndex.get(orderIndex.toString());
  if (order === undefined) {
    throw new Error('cancelOrder cant get orderIndex');
  }
  const digest = digestOrder(state.address, order);
  const cancelOrderWithProofs = getOrderWithProofs(state, order.index);
  let nextOrderWithProofs = emptyOrderWithProofs;
  let prevOrderWithProofs = emptyOrderWithProofs;
  if (order.isSell) {
    if (state.sellHead === digest) {
      // we are deleting the sellHead
      const nextOrderIndex = state.sellNext.get(order.index.toString());
      if (nextOrderIndex === undefined || nextOrderIndex.eq(0)) {
        // there is no other sellorders
        state.sellHead = HEX_0;
      } else {
        const nextOrder = state.OrdersByIndex.get(nextOrderIndex.toString());
        if (nextOrder === undefined) {
          throw new Error('cancelOrder cant get nextOrder');
        }
        const nextDigest = digestOrder(state.address, nextOrder);
        nextOrderWithProofs = getOrderWithProofs(state, nextOrderIndex);
        state.sellHead = nextDigest;
        state.sellNext.delete(order.index.toString());
      }
      state.Fills.delete(orderIndex.toString());
    } else {
      // sellHead is not my canceled order.

      const prevOrder = state.OrdersByIndex.get(getPrevOrder(state, order).toString());
      if (prevOrder === undefined) {
        throw new Error('cancelOrder cant get prevOrder');
      }
      prevOrderWithProofs = getOrderWithProofs(state, prevOrder.index);

      const cancelNextIndex = state.sellNext.get(order.index.toString());
      if (cancelNextIndex !== undefined) {
        nextOrderWithProofs = getOrderWithProofs(state, cancelNextIndex);
      }

      state.sellNext.set(prevOrder.index.toString(), nextOrderWithProofs.order.index);
    }
  } else {
    // is buy
    if (state.buyHead === digest) {
      // we are deleting the sellHead
      const nextOrderIndex = state.buyNext.get(order.index.toString());
      if (nextOrderIndex === undefined || nextOrderIndex.eq(0)) {
        // there is no other sellorders
        state.buyHead = HEX_0;
      } else {
        const nextOrder = state.OrdersByIndex.get(nextOrderIndex.toString());
        if (nextOrder === undefined) {
          throw new Error('cancelOrder cant get nextOrder');
        }
        const nextDigest = digestOrder(state.address, nextOrder);
        nextOrderWithProofs = getOrderWithProofs(state, nextOrderIndex);
        state.buyHead = nextDigest;
        state.buyNext.delete(order.index.toString());
      }
      state.Fills.delete(orderIndex.toString());
    } else {
      // buyHead is not my canceled order.
      const prevOrder = state.OrdersByIndex.get(getPrevOrder(state, order).toString());
      if (prevOrder === undefined) {
        throw new Error('cancelOrder cant get prevOrder');
      }
      prevOrderWithProofs = getOrderWithProofs(state, prevOrder.index);

      const cancelNextIndex = state.buyNext.get(order.index.toString());
      if (cancelNextIndex !== undefined) {
        nextOrderWithProofs = getOrderWithProofs(state, cancelNextIndex);
      }

      state.buyNext.set(prevOrder.index.toString(), nextOrderWithProofs.order.index);
    }
  }

  state.OrdersByDigest.delete(digest);
  state.OrdersByIndex.delete(order.index.toString());
  return [
    state,
    {
      cancelOrder: cancelOrderWithProofs,
      nextOrder: nextOrderWithProofs,
      prevOrder: prevOrderWithProofs,
    },
  ];
}
async function updateCancelledOrders(factoryState: FactoryState, state: OrderBookState): Promise<OrderBookState> {
  state.cancelledOrders = [];
  for (const [, order] of state.OrdersByIndex) {
    if (order.isCollateralOrder) {
      if ((await factoryState.cashWallet.Balances(order.maker)).lt(order.orderPrice)) {
        let cancelOrderRaw: CancelOrderReqRaw;

        [state, cancelOrderRaw] = await modifyStateForCancelOrder(state, order.index);
        const cancelOrder: CancelOrderReq = {
          ...cancelOrderRaw,
          cancelOrderSignature: [],
        };
        state.cancelledOrders.push(cancelOrder);
      }
    }
  }
  if (state.cancelledOrders.length === 0) {
    state.cancelledOrders = [emptyCancelOrderReq];
  }
  return state;
}
function getPrevOrder(state: OrderBookState, order: Order): BigNumber {
  if (order.isSell) {
    const sellHeadOrder = state.OrdersByDigest.get(state.sellHead);
    if (sellHeadOrder === undefined) {
      throw new Error('getPrevOrder there is no sell head');
    }
    if (order.index === sellHeadOrder.index) {
      throw new Error('you are at sellHead, no prevOrder');
    }
    let nextOrderIndex = state.sellNext.get(sellHeadOrder.index.toString());
    if (nextOrderIndex === undefined) {
      throw new Error('cant get nextOrder at sellhead');
    }
    let prevOrderIndex = sellHeadOrder.index;
    while (nextOrderIndex !== order.index) {
      prevOrderIndex = nextOrderIndex;
      nextOrderIndex = state.sellNext.get(nextOrderIndex.toString());
      if (nextOrderIndex === undefined) {
        throw new Error('cant get nextOrder in loop');
      }
    }
    return prevOrderIndex;
  } else {
    // is Buy
    const buyHeadOrder = state.OrdersByDigest.get(state.buyHead);
    if (buyHeadOrder === undefined) {
      throw new Error('getPrevOrder there is no buy head');
    }
    if (order.index === buyHeadOrder.index) {
      throw new Error('you are at buyHead, no prevOrder');
    }
    let nextOrderIndex = state.buyNext.get(buyHeadOrder.index.toString());
    if (nextOrderIndex === undefined) {
      throw new Error('cant get nextOrder at buyHead');
    }
    let prevOrderIndex = buyHeadOrder.index;
    while (nextOrderIndex !== order.index) {
      prevOrderIndex = nextOrderIndex;
      nextOrderIndex = state.sellNext.get(nextOrderIndex.toString());
      if (nextOrderIndex === undefined) {
        throw new Error('cant get nextOrder in loop');
      }
    }
    return prevOrderIndex;
  }
}
function getOrderWithProofs(state: OrderBookState, index: BigNumber): OrderWithProofs {
  // we are assuming that these orders are already in onchain root

  const leaves = getLeavesFromOrders(state.OrdersByDigest);
  const proofsObj = getProofOfLeafIndex(leaves, index.toNumber());
  const order = state.OrdersByIndex.get(index.toString());
  if (order === undefined) {
    throw new Error('ts reqs this');
  }
  return {
    order,
    proofs: proofsObj.proofs,
    bits: proofsObj.bits,
  };
}
const getOrderBookObj = (provider: JsonRpcProvider, address: string): MerkleOrderbook => {
  return MerkleOrderBookABI && (new BaseContract(address, MerkleOrderBookABI, provider) as MerkleOrderbook);
};
const getPolicyTokenObj = (provider: JsonRpcProvider, address: string): PolicyToken => {
  return PolicyTokenABI && (new BaseContract(address, PolicyTokenABI, provider) as PolicyToken);
};
const getDeploymentBlock = async (ethersAppContext: IEthersContext, contractAddress: string): Promise<number> => {
  if (ethersAppContext.provider === undefined) {
    throw new Error('context provider undefined');
  }
  const receipt = await ethersAppContext.provider.getTransactionReceipt(contractAddress);
  if (!receipt) throw new Error('Smart contract not found');
  return receipt.blockNumber;
};
const getLogForOrderBookAddress = async (
  provider: JsonRpcProvider,
  address: string,
  fromBlock: number
): Promise<LogDescription[]> => {
  const orderBook = getOrderBookObj(provider, address);
  const logs = await (
    await recursiveGetLogs(provider, fromBlock, 'latest', orderBook.address)
  ).map((log) => {
    return orderBook.interface.parseLog(log);
  });
  return logs;
};

export function digestOrder(address: string, order: Order): BytesLike {
  const domain = {
    name: 'MerkleOrderbook',
    version: '1',
    chainId: 31337, // hardhat local chain
    verifyingContract: address,
  };
  const types = {
    Order: [
      { name: 'maker', type: 'address' },
      { name: 'isSell', type: 'bool' },
      { name: 'isCollateralOrder', type: 'bool' },
      { name: 'orderAmount', type: 'uint256' },
      { name: 'orderPrice', type: 'uint256' },
      { name: 'index', type: 'uint256' },
    ],
  };
  return utils.keccak256(utils._TypedDataEncoder.encode(domain, types, order));
}
