import { BigNumber, constants } from 'ethers';
import { BytesLike } from 'ethers/lib/utils';
import {
  CashWallet,
  MerkleOrderbook,
  MerkleOrderbookFactory,
  OrderBookRouter,
  PolicyToken,
  PolicyWallet,
  FiatTokenV1,
} from '@scaffold-eth/common/src/generated/contract-types';
export type Order = {
  maker: string;
  isSell: boolean;
  isCollateralOrder: boolean;
  orderAmount: BigNumber;
  orderPrice: BigNumber;
  index: BigNumber;
};
export type RawOrder = {
  maker: string;
  isSell: boolean;
  isCollateralOrder: boolean;
  orderAmount: BigNumber;
  orderPrice: BigNumber;
};
export type RawOrderWithOrderBookIndex = RawOrder & {
  orderBookIndex: BigNumber;
};

export type OrderWithProofs = {
  order: Order;
  proofs: BytesLike[];
  bits: number;
};
export type CancelOrderReqRaw = {
  cancelOrder: OrderWithProofs;
  nextOrder: OrderWithProofs;
  prevOrder: OrderWithProofs;
};
export type CancelOrderReq = CancelOrderReqRaw & {
  cancelOrderSignature: BytesLike;
};

export type MakeOrderReqRaw = {
  newOrder: OrderWithProofs;
  headOrder: Order;
  prevOrder: OrderWithProofs;
  injectOrder: OrderWithProofs;
};
export type MakeOrderReq = MakeOrderReqRaw & {
  newOrderSignature: BytesLike;
};

export type RouterMakeOrderReq = {
  orderBookIndex: BigNumber;
  cancelledOrders: CancelOrderReq[];
  req: MakeOrderReq;
};
export type RouterTakeReq = {
  orderBookIndex: BigNumber;
  amount: BigNumber;
  headOrder: OrderWithProofs;
  headsNextOrder: OrderWithProofs;
  cancelledOrders: CancelOrderReq[];
};

export type TakeReqRaw = {
  isSell: boolean;
  orderBookIndex: BigNumber;
  amount: BigNumber;
};
export type FactoryState = {
  factory: MerkleOrderbookFactory;
  orderBookRouter: OrderBookRouter;
  cashWallet: CashWallet;
  policyWallet: PolicyWallet;
  orderbooks: [string, number][];
  cashToken: FiatTokenV1;
};
export type OrderBookState = {
  orderBook: MerkleOrderbook;
  policyToken: PolicyToken;
  OrdersByIndex: Map<string, Order>;
  OrdersByDigest: Map<BytesLike, Order>;
  cashToken: FiatTokenV1;
  sellHead: BytesLike;
  buyHead: BytesLike;
  Fills: Map<string, BigNumber>;
  sellNext: Map<string, BigNumber>;
  buyNext: Map<string, BigNumber>;
  orderBookIndex: number | undefined;
  address: string;
  cancelledOrders: CancelOrderReq[];
};
export const HEX_0: BytesLike = '0x0000000000000000000000000000000000000000000000000000000000000000';

export const emptyOrder: Order = {
  maker: constants.AddressZero,
  orderAmount: BigNumber.from(0),
  orderPrice: BigNumber.from(0),
  isSell: false,
  isCollateralOrder: false,
  index: BigNumber.from(0),
};

export const emptyOrderWithProofs: OrderWithProofs = {
  order: emptyOrder,
  proofs: [],
  bits: 0,
};
export const emptyCancelOrderReq: CancelOrderReq = {
  cancelOrder: emptyOrderWithProofs,
  cancelOrderSignature: HEX_0,
  nextOrder: emptyOrderWithProofs,
  prevOrder: emptyOrderWithProofs,
};
export type NewPolicyReq = {
  policyName: string;
  policySymbol: string;
  firstOwner: string;
  startDate: BigNumber;
  guranteedPayouts: BigNumber[];
  nonGuranteedPayouts: BigNumber[];
};
