import { JsonRpcProvider, Log } from '@ethersproject/providers';

export async function recursiveGetLogs(
  provider: JsonRpcProvider,
  fromBlock: number,
  toBlock: number | string,
  address: string
): Promise<Log[]> {
  const MAX_QUERY = 100000;
  console.log('recursiveGetLogs provider', provider);
  if (toBlock === 'latest') {
    toBlock = await provider.getBlockNumber();
  } else if (typeof toBlock === 'string') {
    throw new Error('only latest is allowed in string');
  }
  let currentTo = fromBlock + MAX_QUERY;
  let lastFrom = fromBlock;
  let logs: Log[] = [];
  while (currentTo < toBlock) {
    const log = await provider.getLogs({
      fromBlock: lastFrom,
      toBlock: currentTo,
      address,
    });
    if (!log) {
      throw new Error('provider.getLogs failed');
    }
    console.log('got log', log);
    logs = logs.concat(log);
    lastFrom += MAX_QUERY;
    currentTo += MAX_QUERY;
  }
  const log = await provider.getLogs({
    fromBlock: lastFrom,
    toBlock: currentTo,
    address,
  });
  if (!log) {
    throw new Error('provider.getLogs failed');
  }
  return logs.concat(log);
}
