import { JsonRpcProvider } from '@ethersproject/providers';
import { MerkleOrderbookFactory } from '~common/generated/contract-types';
import { recursiveGetLogs } from './CommonUtils';

export async function getNewOrderBookEvents(
  provider: JsonRpcProvider,
  merkleOrderbookFactory: MerkleOrderbookFactory
): Promise<[string, number][]> {
  // dont have deployTransaction when not in hardhat, just get from 0
  // @ToDo mumbai block 31400000 , should take in some deployment time var
  const logs = await (
    await recursiveGetLogs(provider, 31400000, 'latest', merkleOrderbookFactory.address)
  )
    .map((log) => {
      return merkleOrderbookFactory.interface.parseLog(log);
    })
    .filter((log) => log.name === 'NewOrderBook')
    .map((log): [string, number] => {
      return [log.args.orderBookAddress, Number(log.args.blockNumber)];
    });
  return logs;
}
