import { HEX_0 } from './types';
import { BytesLike } from 'ethers';
import { arrayify, defaultAbiCoder } from 'ethers/lib/utils';
import sha3 from 'js-sha3';
const SIZE = 255;
const DEPTH = 8;
export function hashLeavesOneTier(leaves: BytesLike[]): BytesLike[] {
  const hashedLeaves: BytesLike[] = [];
  for (let i = 0; i < leaves.length; i += 2) {
    const left = leaves[i];
    const right = leaves[i + 1] || HEX_0;
    if (left === undefined) {
      console.error('leaves', leaves);
      throw new Error('hashLeavesOneTier left undefined');
    }
    hashedLeaves.push(merkleHash([left, right]));
  }
  return hashedLeaves;
}
export function getProofOfLeafIndex(leaves: BytesLike[], index: number) {
  const proofs: BytesLike[] = [];
  let bits = 0;
  let leaf = leaves[index];
  let currentHeight = 0;
  while (leaves.length > 1) {
    const tierSibling = getSiblingForIndex(leaves, index);
    proofs.push(tierSibling);
    if (tierSibling !== HEX_0) {
      bits += bitmap(currentHeight);
    }
    if (index % 2) {
      // odd
      leaf = merkleHash([tierSibling, leaf]);
    } else {
      leaf = merkleHash([leaf, tierSibling]);
    }

    leaves = hashLeavesOneTier(leaves);
    index = Math.floor(index / 2);
    currentHeight++;
  }

  return {
    proofs,
    bits,
  };
}

export function compute(proofs: BytesLike[], bits: number, index: number, leaf: BytesLike) {
  if (index >= SIZE) {
    throw new Error('_index bigger than tree size');
  }
  if (proofs.length > DEPTH) {
    throw new Error('Invalid _proofs length');
  }

  for (let d = 0; d < DEPTH; d++) {
    if ((index & 1) === 1) {
      if ((bits & 1) === 1) {
        leaf = merkleHash([proofs[d], leaf]);
      } else {
        leaf = merkleHash([HEX_0, leaf]);
      }
    } else {
      if ((bits & 1) === 1) {
        leaf = merkleHash([leaf, proofs[d]]);
      } else {
        leaf = merkleHash([leaf, HEX_0]);
      }
    }
    bits = bits >> 1;
    index = index >> 1;
  }
  return leaf;
}
export function getMerkleRoot(leaves: BytesLike[]): BytesLike {
  while (leaves.length > 1) {
    leaves = hashLeavesOneTier(leaves);
  }
  return leaves[0];
}
export function merkleHash(unsortedArrayOfTwo: BytesLike[]): BytesLike {
  if (unsortedArrayOfTwo.length !== 2) {
    throw 'hashFixed needs array of two';
  }
  if (unsortedArrayOfTwo[0] === HEX_0 && unsortedArrayOfTwo[1] === HEX_0) {
    return HEX_0;
  }
  const encodedLeaf = defaultAbiCoder.encode(['bytes32', 'bytes32'], unsortedArrayOfTwo);
  return keccak256(encodedLeaf);
}

function getSiblingForIndex(leaves: BytesLike[], index: number) {
  if (index % 2) {
    // odd need to return to the left
    return leaves[index - 1] || HEX_0;
  }
  return leaves[index + 1] || HEX_0;
}

function getTreeHeight(treeSize: number) {
  let numLeaves = treeSize + 1; // always include a 0 at index 0
  // let numLeaves = 256; // hardcode this to force running proofs on all heights
  if (numLeaves === 1) {
    // there are no orders in tree
    return 0;
  }
  let height = 0;
  while (numLeaves > 2) {
    if (numLeaves % 2) {
      // if its not even, add 1 to make it even
      numLeaves++;
    }

    height++;
    numLeaves = numLeaves / 2;
  }
  if (numLeaves === 2) {
    height++;
  }
  return height;
}

export function keccak256(data: BytesLike): BytesLike {
  return '0x' + sha3.keccak_256(arrayify(data));
}

export function bitmap(index: number): number {
  const bytePos = -Math.floor(index / 8);
  return (bytePos + 1) * 2 ** Math.floor(index % 8);
}
