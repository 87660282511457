/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createConnectorForFoundryContract, createConnectorForHardhatContract } from 'eth-hooks2/context';
import { invariant } from 'ts-invariant';

import * as toolkitContracts from '~common/generated/contract-types';
import foundryDeployedContractsJson from '~common/generated/foundry_contracts.json';
import hardhatDeployedContractsJson from '~common/generated/hardhat_contracts.json';
import { scaffoldConfig } from '~common/scaffold.config';

/**
 * ⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️⛳️
 * ### Instructions
 * 1. edit externalContracts.config.ts to add your external contract addresses.
 * 2. edit `appContractsConfig` function below and add them to the list
 * 3. run `yarn contracts:build` to generate types for contracts
 * 4. run `yarn deploy` to generate hardhat_contracts.json
 *
 * ### Summary
 * - called  by useAppContracts
 * @returns
 */
export const appContractsConfig = () => {
  try {
    const result = {
      // --------------------------------------------------
      // 🙋🏽‍♂️ Contracts examples either using hardhat or foundry
      // --------------------------------------------------
      MerkleOrderbookFactory:
        scaffoldConfig.build.solidityToolkit === 'hardhat'
          ? createConnectorForHardhatContract(
              'MerkleOrderbookFactory',
              toolkitContracts.MerkleOrderbookFactory__factory,
              hardhatDeployedContractsJson
            )
          : createConnectorForFoundryContract(
              'MerkleOrderbookFactory',
              toolkitContracts.MerkleOrderbookFactory__factory,
              foundryDeployedContractsJson
            ),

      FiatTokenV1:
        scaffoldConfig.build.solidityToolkit === 'hardhat'
          ? createConnectorForHardhatContract(
              'FiatTokenV1',
              toolkitContracts.FiatTokenV1__factory,
              hardhatDeployedContractsJson
            )
          : createConnectorForFoundryContract(
              'FiatTokenV1',
              toolkitContracts.FiatTokenV1__factory,
              foundryDeployedContractsJson
            ),
      SMT:
        scaffoldConfig.build.solidityToolkit === 'hardhat'
          ? createConnectorForHardhatContract('SMT', toolkitContracts.SMT__factory, hardhatDeployedContractsJson)
          : createConnectorForFoundryContract('SMT', toolkitContracts.SMT__factory, foundryDeployedContractsJson),
      OrderBookRouter:
        scaffoldConfig.build.solidityToolkit === 'hardhat'
          ? createConnectorForHardhatContract(
              'OrderBookRouter',
              toolkitContracts.OrderBookRouter__factory,
              hardhatDeployedContractsJson
            )
          : createConnectorForFoundryContract(
              'OrderBookRouter',
              toolkitContracts.OrderBookRouter__factory,
              foundryDeployedContractsJson
            ),
      CashWallet:
        scaffoldConfig.build.solidityToolkit === 'hardhat'
          ? createConnectorForHardhatContract(
              'CashWallet',
              toolkitContracts.CashWallet__factory,
              hardhatDeployedContractsJson
            )
          : createConnectorForFoundryContract(
              'CashWallet',
              toolkitContracts.CashWallet__factory,
              foundryDeployedContractsJson
            ),
      PolicyWallet:
        scaffoldConfig.build.solidityToolkit === 'hardhat'
          ? createConnectorForHardhatContract(
              'PolicyWallet',
              toolkitContracts.PolicyWallet__factory,
              hardhatDeployedContractsJson
            )
          : createConnectorForFoundryContract(
              'PolicyWallet',
              toolkitContracts.PolicyWallet__factory,
              foundryDeployedContractsJson
            ),
      // PolicyToken:
      //   scaffoldConfig.build.solidityToolkit === 'hardhat'
      //     ? createConnectorForHardhatContract(
      //         'PolicyToken',
      //         toolkitContracts.PolicyToken__factory,
      //         hardhatDeployedContractsJson
      //       )
      //     : createConnectorForFoundryContract(
      //         'PolicyToken',
      //         toolkitContracts.PolicyToken__factory,
      //         foundryDeployedContractsJson
      //       ),

      // MerkleOrderbook:
      //       scaffoldConfig.build.solidityToolkit === 'hardhat'
      //         ? createConnectorForHardhatContract(
      //             'MerkleOrderbook',
      //             toolkitContracts.MerkleOrderbook__factory,
      //             hardhatDeployedContractsJson
      //           )
      //         : createConnectorForFoundryContract(
      //             'MerkleOrderbook',
      //             toolkitContracts.MerkleOrderbook__factory,
      //             foundryDeployedContractsJson
      //           ),

      // --------------------------------------------------
      // 🙋🏽‍♂️ Add your external contracts here, make sure to define the address in `externalContractsConfig.ts`Í
      // --------------------------------------------------
      // XSGD: createConnectorForExternalContract('XSGD', externalContracts.XSGD__factory, externalContractsAddressMap),

      // --------------------------------------------------
      // 🙋🏽‍♂️ Add your external abi here (unverified contracts)`
      // --------------------------------------------------
      // YourContractFromAbi: createConnectorForExternalAbi(
      //   'YourContract',
      //   {
      //     [1235]: {
      //       address: 'xxx',
      //     },
      //   },
      //   toolkitContracts.YourContract__factory.abi
      //   // optional if you have a connect function:  externalContracts.YourContract__factory.connect
      // ),
    } as const;

    return result;
  } catch (e) {
    invariant.error(
      '❌ appContractsConfig: ERROR with loading contracts please run `yarn contracts:build or yarn contracts:rebuild`.  Then run `yarn deploy`!'
    );
    invariant.error(e);
    throw e;
  }
};
