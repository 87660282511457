/* eslint-disable @typescript-eslint/no-misused-promises */
import { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers';
import { IEthersContext, TEthersAdaptor } from 'eth-hooks2/models';
import { BigNumber } from 'ethers';
import { NextPage } from 'next';
import { useState } from 'react';

import {
  OrderBookRouter,
  MerkleOrderbookFactory,
  CashWallet,
  PolicyWallet,
  FiatTokenV1,
} from '~common/generated/contract-types';
import { getNewOrderBookEvents } from '~common/lib/FactoryUtils';
import { getOrderBookState, takeManyRouter } from '~common/lib/OrderUtils';
import { FactoryState, OrderBookState, Order, TakeReqRaw } from '~common/lib/types';

type propsType = {
  ethersAppContext: IEthersContext;
  merkleOrderbookFactory: MerkleOrderbookFactory;
  router: OrderBookRouter;
  cashWallet: CashWallet;
  policyWallet: PolicyWallet;
  cashToken: FiatTokenV1;
  mainnetAdaptor: TEthersAdaptor | undefined;
};

const FriendlyPage3: NextPage<propsType> = (props) => {
  const { ethersAppContext, merkleOrderbookFactory, router, cashWallet, policyWallet, cashToken, mainnetAdaptor } =
    props;
  const signer = props.ethersAppContext.signer as JsonRpcSigner;
  // const mainProvider = mainnetAdaptor.provider as JsonRpcProvider;
  // browser state functions and action funcs
  const [factoryState, setFactoryState] = useState<FactoryState>();
  const [allStates, setAllStates] = useState<OrderBookState[]>();
  const getAllStates = async (): Promise<void> => {
    const mainProvider = mainnetAdaptor?.provider as JsonRpcProvider;
    const tmpFactory: FactoryState = {
      factory: merkleOrderbookFactory,
      orderBookRouter: router,
      cashWallet,
      policyWallet,
      cashToken,
      orderbooks: await getNewOrderBookEvents(mainProvider, merkleOrderbookFactory),
    };
    const tmpStates: OrderBookState[] = [];
    for (let i = 0; i < tmpFactory.orderbooks.length; ++i) {
      const state = await getOrderBookState(mainProvider, tmpFactory, i);
      if (!state) {
        throw new Error('cant get state');
      }
      tmpStates.push(state);
    }
    setFactoryState(tmpFactory);
    setAllStates(tmpStates);
  };

  const [sellHeads, setSellHeads] = useState<Array<Order | undefined>>();
  if (allStates && !sellHeads) {
    const tmpSellHeads: Array<Order | undefined> = [];
    for (const state of allStates) {
      tmpSellHeads.push(state.OrdersByDigest.get(state.sellHead));
    }
    setSellHeads(tmpSellHeads);
  }

  const [myPortfoilo, setMyPortfoilo] = useState<BigNumber[]>();
  async function getMyPortfoilo(): Promise<void> {
    if (!factoryState) {
      console.error('getMyPortfoilo called without factoryState');
      throw new Error('need factoryState');
    }
    if (!allStates) {
      console.error('getMyPortfoilo called without allStates');
      throw new Error('need allStates');
    }
    const tmpPortfoilo: BigNumber[] = [];
    for (let i = 0; i < factoryState.orderbooks.length; ++i) {
      const balance: BigNumber = await allStates[i].policyToken.balanceOf(signer._address);
      if (!balance) {
        throw new Error('cant get balance');
      }
      tmpPortfoilo.push(balance);
    }

    setMyPortfoilo(tmpPortfoilo);
  }

  const takeMany = async (): Promise<void> =>
    // rawReqs: TakeReqRaw[],
    {
      // faking rawReqs input
      const rawReqs: TakeReqRaw[] = [
        {
          isSell: true,
          orderBookIndex: BigNumber.from(0),
          amount: BigNumber.from(1),
        },
        {
          isSell: true,
          orderBookIndex: BigNumber.from(1),
          amount: BigNumber.from(1),
        },
        {
          isSell: true,
          orderBookIndex: BigNumber.from(2),
          amount: BigNumber.from(1),
        },
      ];

      if (!factoryState) {
        console.error('takeMany called without factoryState');
        return;
      }
      if (!allStates) {
        console.error('takeMany called without allStates');
        return;
      }
      const [newStates, tx] = await takeManyRouter(factoryState, allStates, rawReqs, signer);

      // setAllStates, we are setting the values locally even if tx fails
      // should catch tx success response first?
      console.log('tx', tx);
      setAllStates(newStates);
    };

  return (
    <div>
      test
      <button onClick={getAllStates}>getOrderBooks button</button>
      {sellHeads ? (
        <div>
          {JSON.stringify(sellHeads)}
          <button onClick={takeMany}>takeMany</button>
        </div>
      ) : (
        <div>states not loaded</div>
      )}
      {myPortfoilo ? (
        <div>
          {JSON.stringify(myPortfoilo, null, 4)}
          <button onClick={getMyPortfoilo}> update portfoilo</button>
        </div>
      ) : (
        <div>
          no portfoilo
          <button onClick={getMyPortfoilo}> update portfoilo</button>
        </div>
      )}
    </div>
  );
};

export default FriendlyPage3;
